import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)
        this.button = $(this.wrapper).find('span')
        this.max_counter = 11
        this.counter = this.max_counter
        this.button.on('click', this._toggle_counter)
    }

    disconnect() {
        this.button.unbind('click')
    }

    _toggle_counter = () => {
        if(this.counter < this.max_counter) {
            this._reset_counting()
        } else {
            this.counter -= 1
            this._step_counting()
        }
    }

    _step_counting = () => {
        if(this.counter > 0 && this.counter != this.max_counter) {
            this.button.text(`Restart systemu za ${this.counter} s. Naciśnij, aby anulować`)
            this.counter -= 1
            setTimeout(this._step_counting, 1000)
        } else if(this.counter == 0) {
            this._restart_system()
        }
    }

    _reset_counting = () => {
        this.button.text("<span class='glyphicon glyphicon-refresh' aria-hidden='true'></span>Restart systemu")
        this.counter = this.max_counter
    }

    _restart_system = () => {
        this.button.text("Restartowanie systemu...")
        $.ajax({
            type: 'POST',
            url: JsRoutesRails.restart_system_path()
        })
    }
}