import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)

        this.archivedMailId = this.wrapper.data('archivedMailId')
        this.trContentId = this.wrapper.data('trContentId')
        this.trContent = $(`#${this.trContentId}`)

        this.buttonContentId = `${this.trContentId}_button`
        this.buttonContent = $(`#${this.buttonContentId}`)
        this.buttonContent.on('click', this._load_content)
    }

    disconnect() {
        this.buttonContent.unbind('click')
    }

    _load_content = () => {
        console.log("load content")
        Rails.ajax({
            url: 'archived_mails/' + this.archivedMailId,
            type: 'get',
            success: this._show_content
        });
    }

    _show_content = (archivedMailContent) => {
        this.trContent.html(archivedMailContent.body.innerHTML)
    }
}